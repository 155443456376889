.splash-page {
  background: black;
  height: 100vh;
  overflow: hidden;
  .logo {
    display: block;
    height: 5em;
    width: 10em;
    margin-top: calc(20vh - 5vw);
    margin-left: auto;
    margin-right: auto;
  }
  .title {
    text-align: center;
    font-size: 20px;
    color: #36ade3;
    font-weight: 500;
  }
  .get-started-button {
    width: 33%;
    margin-left: 33%;
    margin-top: calc(20vh - 5vw);
    font-size: 20px;
  }
  .have-an-account {
    color: #aeaeae;
    text-align: center;
    margin-top: 2em;
  }
  .sign-in {
    cursor: pointer;
    width: 100vw;
    text-align: center;
    color: #33A6FF;
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
  }
  .disabled {
    opacity: 0.7;
  }
  .bottom-text {
    position: fixed;
    text-align: center;
    font-size: 12px;
    color: #36ade3;
    font-weight: 500;
    bottom: 0px;
    width: 100%;
    left: 0px;
  }
  .sentinel-logo {
    display: inline-block;
    height: 15px;
    width: 14px;
    // margin-left: auto;
    // margin-right: auto;
    margin-left: 5px;
  }
  .coming-soon {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: white;
    font-weight: 500;
  }
}
